/* App-specific styles */
.App {
    font-family: Arial, sans-serif;
}

/* Reset some basic styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
}

h1, h2, h3 {
    font-family: 'Arial', sans-serif;
}

/* Header styles */
.header {
    background-color: #333;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .logo h1 {
    font-size: 24px;
}

.header nav ul {
    display: flex;
}

.header nav ul li {
    margin-left: 20px;
}

.header nav ul li a {
    color: #fff;
    font-size: 16px;
}

.header nav ul li a:hover {
    color: #ccc;
}

/* Authentication buttons */
.auth-buttons {
    display: flex;
    gap: 20px;
}

.auth-buttons .btn {
    background-color: #ff7f50;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}

.auth-buttons .btn:hover {
    background-color: #ff5a2f;
}

.auth-buttons .btn-register {
    background-color: #4caf50;
}

.auth-buttons .btn-register:hover {
    background-color: #45a049;
}

/* Services section styles */
.services {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
}

.services h2 {
    font-size: 32px;
    margin-bottom: 40px;
}

.service-item {
    background-color: #f9f9f9;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.service-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.service-item p {
    font-size: 16px;
    color: #666;
}

/* Hosting Plans Section */
.hosting-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
}

/* Hosting Plan Styles */
.hosting-plan {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
}

.hosting-plan h4 {
    font-size: 24px;
    margin-bottom: 10px;
}

.hosting-plan .price {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
}

.hosting-plan .features {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
    color: #666;
}

.hosting-plan .features li {
    margin-bottom: 8px;
}

/* Show More Button Styling */
.show-more-btn {
    text-align: center;  /* Center align the button */
    margin-top: 30px;     /* Add margin above to space out the button */
}

/* Show More Button Style */
.show-more-btn .btn-secondary {
    background-color: #f7a300;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.show-more-btn .btn-secondary:hover {
    background-color: #e68900;
}

/* Price Cards Section */
.price-card {
    background-color: #f4f4f4;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.price-card h4 {
    font-size: 20px;
    margin-bottom: 10px;
}

.price-card p {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    margin-bottom: 20px;
}

.price-card .btn-primary {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}

.price-card .btn-primary:hover {
    background-color: #0056b3;
}

/* Domain pricing section */
.domain-pricing {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
}

.pricing-option {
    text-align: center;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pricing-option h4 {
    font-size: 24px;
    margin-bottom: 10px;
}

.pricing-option p {
    font-size: 20px;
    color: #333;
    font-weight: bold;
}

.contact-us {
    margin-top: 20px;
    font-size: 16px;
    color: #007bff;
    text-decoration: underline;
}

/* Footer styles */
.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
}
